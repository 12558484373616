import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Header from "./header/header"
import "./reset.css"
import "./layout.scss"
import Container from "@material-ui/core/Container"
import n from "../services/NullIf"
import Footer from "../components/footer/footer"
import ThemeContext from "../context/ThemeContext"
import SubNav from "../components/sub-nav/sub-nav"
import Login from "../components/login/login"
import { getLocaleAlias, localizeItem } from "../graphql-assistant/createLocaleTextGetter"

const Layout = ({ children, pageContext, location, silo, breadcrumbNameOverride, hideBreadcrumb, bcDark }) => {

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      sanitySilo (_id: {eq: "382bf7f6-ce31-436f-9100-fbad76571f52"}){
          _id
          breadcrumbTrail {
              ...LocaleSiloTrails
          }
      }
    }
  `)

  let site = useContext(ThemeContext);
  let loginOpen = site.loginOpen
  let loginIndex = site.loginIndex
  let locale = site.locale;


  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }

  const secondNav = (type) => {
    return <SubNav location={location} type={type} />
  }

  const lastItem = location && location.pathname.split('/').filter(x => x)[location.pathname.split('/').filter(x => x).length - 1];
  if (!silo){
    silo = localizeItem(data.sanitySilo, [locale]);
  }
  const localeAlias = getLocaleAlias(locale);

  return (
    <ThemeContext.Consumer>
      {theme => (
        <Container maxWidth="xl" className="appContainer">
          <Header siteTitle={data.site.siteMetadata.title} pageContext={pageContext}/>
          <Login isOpen={loginOpen} tabIndex={loginIndex} isLoggedIn={false} />
          {silo._id === '3aacbadb-2f40-40ba-bcb8-f6deeb25edb4' ? secondNav("bonus") : ''}
          {silo._id === '382bf7f6-ce31-436f-9100-fbad76571f52' ? secondNav("casinos") : ''}
          {!hideBreadcrumb && location && !(location.pathname.includes('/go/') || location.pathname.includes('/mene/')) &&
            <div className={`bread-crumb ${bcDark ? "bc-dark" : ""}`}>
              <Link style={{ color: "black" }} to={(localeAlias + '/')}>
                {localeAlias} <NavigateNextIcon />
              </Link>
              {(silo && silo.breadcrumbTrail && silo.breadcrumbTrail.length && silo.breadcrumbTrail.length > 0) ? silo.breadcrumbTrail.map(x =>
                x.value !== lastItem && <Link style={{ color: "black" }} to={(localeAlias + '/' + x.value + '/')}>
                  {x.name} <NavigateNextIcon />
                </Link>
              ) : ''
              }
              {location && lastItem !== localeAlias && lastItem !== 0 &&
                <Link style={{ color: "black" }} to={(location.pathname)}>
                  {breadcrumbNameOverride ? breadcrumbNameOverride : lastItem}
                </Link>
              }
            </div>
          }
          <div>
            <main>
              {children}
            </main>
            <footer>
              <Footer location={location} />
            </footer>
          </div>
        </Container>
      )}
    </ThemeContext.Consumer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
