import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useEffect, useContext, useState } from "react"
import ThemeContext from "../../context/ThemeContext"
import styles from "./login.module.scss"
import r from "../../services/Router"
import t from "../../services/GetTranslationValue"
import { getLocaleAlias, localizeItem } from "../../graphql-assistant/createLocaleTextGetter"
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button"
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Tab, Tabs, Typography } from "@material-ui/core"
import Dialog from '@material-ui/core/Dialog';
import { navigate } from "gatsby";
import { getUser, isLoggedIn, setUser, logout } from "../../services/auth";
import PersonIcon from '@material-ui/icons/Person';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import { canUseDOM } from '../../services/dom-helpers'
import { API_ROOT } from "gatsby-env-variables"
import Checkbox from '@material-ui/core/Checkbox';


const Login = ({ isOpen, tabIndex, lpClose }) => {

  const [name, setName] = useState("");
  const [signUpError, setSignUpError] = useState("");
  const [showError, setShowError] = useState(false);
  const [userNameLogin, setUserNameLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");
  const [userNameSignup, setUserNameSignup] = useState("");
  const [passwordSignup, setPasswordSignup] = useState("");
  const [loginPassVisibility, setLoginPassVisibility] = useState(false);
  const [signupPassVisibility, setSignupPassVisibility] = useState(false);
  const { setProfile } = useContext(ThemeContext);
  const [termsChecked, setTermsChecked] = useState(false);

  let [loginOpen, setLoginOpen] = useState(false);
  let [loginIndex, setLoginIndex] = useState(0);

  let site = useContext(ThemeContext);
  let locale = site.locale;
  let translations = site.translations;
  const currentPage = canUseDOM ? window.location.pathname.split('/').splice(2).join('__') : '__';
  const redirectUrl = canUseDOM ? currentPage : t.get(translations, "My Account Url");
  let user = site.user;

  const alias = getLocaleAlias(locale);

  const login = async () => {
    const body = { email: userNameLogin, password: passwordLogin };
    const response = await fetch(`${API_ROOT}/login`, {
      mode: 'cors',
      method: 'post',
      headers: new Headers({ 'Content-type': 'application/json' }),
      body: JSON.stringify(body),
    });
    const result = await response.json();
    setSignUpError('')
    if (result.error) {
      setSignUpError(t.get(translations, result.error.description))
      setProfile({})
      toggleError()
    }
    if (result.user) {
      setUser({ email: result.user.email, token: result.user.token })
      setProfile(result)
      navigate(r.get(locale, translations, "myAccount", ""))
    }
  }

  const signup = async () => {
    const body = { locale: alias, name: name, email: userNameSignup, password: passwordSignup };
    const response = await fetch(`${API_ROOT}/register`, {
      method: 'post',
      mode: 'cors',
      headers: new Headers({ 'Content-type': 'application/json' }),
      body: JSON.stringify(body),
    });
    const result = await response.json();
    setSignUpError('')
    if (result.error) {
      setSignUpError(t.get(translations, result.error.description))
      toggleError()
    }
    if (result.errors) {
      let [ err ] = result.errors
      setSignUpError(t.get(translations, err.msg))
      toggleError()
    }
    if (result.user) {
      //console.log(result.user)
      setUser({ email: result.user.email, token: result.user.token })
      setProfile(result)
      navigate(r.get(locale, translations, "myAccount", ""))
    }
  }

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (loginIndex === 0 && userNameLogin !== "" && passwordLogin !== "") {
          login()
        }
        if (loginIndex === 1 && name !== "" && userNameSignup !== "" && passwordSignup !== "") {
          signup()
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [loginIndex, userNameLogin, passwordLogin, name, userNameSignup, passwordSignup]);


  function TabsIndex(index) {
    return {
      id: `horizontal-tab-${index}`,
      "aria-controls": `horizontal-tabpanel-${index}`,
    }
  }

  const tabChange = (event, newValue) => {
    setLoginIndex(newValue);
  }

  const loginClose = (event, newValue) => {
    setLoginOpen(false);
    lpClose()
  }

  const toggleError = () => {
    setShowError(!showError);
  }

  const toggleTerms = () => {
    setTermsChecked(!termsChecked);
  }

  useEffect(() => {
    setLoginOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog maxWidth="md" fullWidth open={loginOpen} onClose={loginClose} className={`login-popup ${styles.loginPopup}`}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key={`bottom, center`}
        open={showError}
        onClose={toggleError}
        message={signUpError}
        autoHideDuration={5000}
        className={`${styles.loginAlert}`}
      />
      <div className={`${styles.loginBenefits}`}>
        <div className={`${styles.loginBenefitsText}`}>
          <p>{t.get(translations, "login-why-join-mr-gamble")}?</p>
          <ul>
            <li><span>{t.get(translations, "login-rate-your-experience-with-casinos")}</span></li>
            <li><span>{t.get(translations, "login-add-reviews-for-your-favourite-casinos")}</span></li>
            <li><span>{t.get(translations, "login-share-videos-and-images-of-your-big-wins")}</span></li>
            <li><span>{t.get(translations, "login-receive-points-for-your-activities")}</span></li>
            <li><span>{t.get(translations, "login-and-many-more-perks")}...</span></li>
          </ul>
        </div>
      </div>
      <div className={`${styles.formsWrapper}`}>
        <Tabs
          orientation="horizontal"
          value={loginIndex}
          onChange={tabChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          className={`gamble-tab ${styles.tabs}`}
        >
          <Tab
            label={t.get(translations, "login-login")}
            {...TabsIndex(0)}
            className={`${styles.tab}`}
          />
          <Tab
            label={t.get(translations, "login-signup")}
            {...TabsIndex(1)}

            className={`${styles.tab}`}
          />
        </Tabs>
        <div style={{ display: `${loginIndex === 0 ? 'block' : 'none'}` }} value={loginIndex} className={`${styles.tabContent}`}>
          <TextField value={userNameLogin}
            onChange={e => setUserNameLogin(e.target.value)}
            id="outlined-basic" label={t.get(translations, "login-email-address")} variant="outlined" fullWidth className={`${styles.emailAddress}`}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                {userNameLogin === "" ? "" :
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setUserNameLogin("")}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                }
              </InputAdornment>,
            }}
          />
          <TextField
            value={passwordLogin}
            onChange={e => setPasswordLogin(e.target.value)}
            id="filled-login-password-input"
            label={t.get(translations, "login-password")}
            type={loginPassVisibility ? 'text' : 'password'}
            autoComplete="current-password"
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setLoginPassVisibility(!loginPassVisibility)}
                  edge="end"
                >
                  {loginPassVisibility ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>,
            }}
            className={`${styles.password}`}
          />
          {/* <p className={`${styles.policy}`}>
            <Checkbox
              checked={termsChecked}
              onChange={toggleTerms}
              inputProps={{ 'aria-label': 'Terms checkbox' }}
            />
            <span onClick={toggleTerms}>{t.get(translations, "login-register-terms-warning")}
              <Link key={"Terms and Conditions"} to={alias + "/" + "terms-of-service" + "/"}>: {t.get(translations, "login-register-terms-of-use")}</Link>
            </span>
          </p> */}
          <div className={`${styles.loginButtonsContainer}`}>
            <Button disabled={(userNameLogin === "" || passwordLogin === "")} onClick={async () => await login()} color="primary" fullWidth variant="contained" className={`${styles.signIn}`}>
              <PersonIcon />
              {t.get(translations, "login-sign-in")}
            </Button>
            <Button fullWidth color="primary" variant="contained" className={`${styles.twitchLogin}`}>
              <span className={`${styles.icon}`}></span>

              <a href={`${API_ROOT}/auth/twitch/login/${getLocaleAlias(locale)}/${redirectUrl}`}>{t.get(translations, "login-sign-in-with-twitch")}</a>
            </Button>
            {/* <Link className={`${styles.forgotPassword}`} to={"/my-account"}>
              Forgot your password?
            </Link> */}
          </div>
        </div>
        <div style={{ display: `${loginIndex === 1 ? 'block' : 'none'}` }} value={loginIndex} index={1} className={`${styles.tabContent}`}>
          <TextField value={name}
            onChange={e => setName(e.target.value)}
            id="outlined-basict" label={t.get(translations, "login-name")} variant="outlined" required fullWidth className={`${styles.name}`}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                {name === "" ? "" :
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setName("")}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                }
              </InputAdornment>,
            }}
          />
          <TextField value={userNameSignup}
            onChange={e => setUserNameSignup(e.target.value)}
            id="outlined-basics" label={t.get(translations, "login-email-address")} variant="outlined" required fullWidth className={`${styles.emailAddress}`}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                {userNameSignup === "" ? "" :
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setUserNameSignup("")}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                }
              </InputAdornment>,
            }}
          />
          <TextField
            value={passwordSignup}
            onChange={e => setPasswordSignup(e.target.value)}
            id="filled-password-input"
            label={t.get(translations, "login-password")}
            type={signupPassVisibility ? 'text' : 'password'}
            autoComplete="current-password"
            variant="outlined"
            fullWidth
            required
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setSignupPassVisibility(!signupPassVisibility)}
                  edge="end"
                >
                  {signupPassVisibility ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>,
            }}
            className={`${styles.password}`}
          />
          <p className={`${styles.policy}`}>
            <Checkbox
              checked={termsChecked}
              onChange={toggleTerms}
              inputProps={{ 'aria-label': 'Terms checkbox' }}
              className={`${styles.policyCheck}`}
            />
            <span onClick={toggleTerms}>{t.get(translations, "login-register-terms-warning")}
              <Link style={{textTransform: 'none'}} key={"Terms and Conditions"} to={alias + "/" + t.get(translations, "t-and-c-link") + "/"}> {t.get(translations, "login-register-terms-link-word")}</Link>
            </span>
          </p>
          <div className={`${styles.signupButtonsContainer}`}>
            <Button disabled={(name === "" || userNameSignup === "" || passwordSignup === "" || termsChecked === false)} onClick={async () => await signup()} color="primary" fullWidth variant="contained" className={`${styles.signUp}`}>
              {t.get(translations, "login-signup")}
            </Button>
            <Button className={`${styles.haveAccount}`} onClick={() => setLoginIndex(0)}>
              {t.get(translations, "login-already-have-an-account")}?
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
export default Login
