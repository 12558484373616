import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useContext, useState } from "react"
import ThemeContext from "../../context/ThemeContext"
import styles from "./slider.module.scss"
import r from "../../services/Router"
import t from "../../services/GetTranslationValue"
import { getLocaleAlias, localizeItem } from "../../graphql-assistant/createLocaleTextGetter"
import IconButton from '@material-ui/core/IconButton';
import Button from "@material-ui/core/Button"
import Swiper from "react-id-swiper"
import "../../../public/styles/swiper.min.css"
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';


const Slider = ({children, disableNav, navTopMargin, hideNavOnMobile}) => {

  const [swiper, setSwiper] = useState(null);

  // let { translations, locale } = useContext(ThemeContext)

  const params = {
    slidesPerView: 'auto',
    spaceBetween: 5,
    freeMode: true,
    // },
    // breakpoints: {
    //   1280: {
    //     slidesPerView: 2,
    //     spaceBetween: 30,
    //   },
    //   960: {
    //     slidesPerView: 1,
    //     spaceBetween: 30,
    //   },
    //   600: {
    //     slidesPerView: 2,
    //     spaceBetween: 30,
    //   },
    //   0: {
    //     slidesPerView: 1,
    //     spaceBetween: 30,
    //   },
    // },
  }

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  }

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  }

  return (
    <div className={`gamble-slider-navigation ${styles.sliderContainer} ${disableNav ? styles.hideNav : ""} ${hideNavOnMobile ? styles.navHidden : ""}`}>
      <Swiper {...params} getSwiper={setSwiper}>
        {children}
      </Swiper>
      {!disableNav && [
        <IconButton aria-label="previous" onClick={goPrev} className={`${hideNavOnMobile ? "gamble-hidden-md-down" : ""} ${styles.sliderPrev}`} style={{ top: navTopMargin }}>
          <ChevronLeftIcon />
        </IconButton>,
        <IconButton aria-label="next" onClick={goNext} className={`${hideNavOnMobile ? "gamble-hidden-md-down" : ""} ${styles.sliderNext}`} style={{ top: navTopMargin }}>
          <ChevronRightIcon />
        </IconButton>
        ]
      }
    </div>
  )
}
export default Slider