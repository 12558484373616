import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useContext } from "react"
import styles from "./sub-nav.module.scss"
import r from "../../services/Router"
import t from "../../services/GetTranslationValue"
import ThemeContext from "../../context/ThemeContext"
import ToggleButton from '@material-ui/lab/ToggleButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Slider from "../slider/slider"

const SubNav = ({location, type}) => {

  let { translations, locale } = useContext(ThemeContext);

  const [linksOpen, setLinksOpen] = React.useState(false);

  const navLinks = {
    bonus: [
      // Fi
      { name: "Bonukset", url: "/kasinobonukset/", l: "fi" },
      { name: "Ilmaiskierroksia ilman talletusta", url: "/ilmaiskierrokset/", l: "fi" },
      { name: "Kaikki ilmaiskierrokset", url: "/ilmaiskierrokset/", l: "fi" },
      { name: "Vedonlyöntibonukset", url: "/vedonlyontibonukset/", l: "fi" },
      { name: "Ilmaista pelirahaa", url: "/ilmaista-pelirahaa/", l: "fi" },
      { name: "Non sticky / forfeitable bonus", url: "/non-sticky-bonukset/", l: "fi" },
      { name: "Talletusbonus", url: "/talletusbonukset/", l: "fi" },
      { name: "Ilman talletusta", url: "/no-deposit-bonus/", l: "fi" },
      { name: "Eksklusiiviset bonukset", url: "/eksklusiiviset-bonukset/", l: "fi" },
      { name: "Highroller bonukset", url: "/high-roller-bonukset/", l: "fi" },
      { name: "Reload bonukset", url: "/reload-bonukset/", l: "fi" },
      { name: "Cashback bonukset", url: "/cashback-bonukset/", l: "fi" },
      { name: "Bonuskoodit", url: "/bonuskoodit/", l: "fi" },
      // En
      { name: "Bonus", url: "/casino-bonus/", l: "en" },
      { name: "freespins", url: "/free-spins/", l: "en" },
      { name: "No deposit bonus", url: "/no-deposit-bonus/", l: "en" },
      { name: "Deposit bonuses", url: "/casino-deposit-bonus/", l: "en" },
      { name: "Cashback Bonus", url: "/cashback-bonus/", l: "en" },
      { name: "Reload Bonus", url: "/casino-reload-bonus/", l: "en" },
      { name: "Bonus Codes", url: "/best-bonus-codes-for-online-casinos/", l: "en" },
      { name: "HighRoller Bonus", url: "/highroller-casino-bonus/", l: "en" },
      { name: "Non-sticky Bonus", url: "/best-forfeitable-casino-bonuses/", l: "en" },
      { name: "Forfeitable Bonus", url: "/best-forfeitable-casino-bonuses/", l: "en" },
      { name: "Exclusive Bonus", url: "/exclusive-casino-bonus/", l: "en" },
      { name: "Sportsbook Bonuses", url: "/casino-with-sportsbook/", l: "en" },
      // Ca

      { name: "Bonus", url: "/casino-bonus/", l: "enCA" },
      { name: "freespins", url: "/free-spins/", l: "enCA" },
      { name: "No deposit bonus", url: "/no-deposit-bonus/", l: "enCA" },
      { name: "Deposit bonuses", url: "/casino-deposit-bonus/", l: "enCA" },
      { name: "Cashback Bonus", url: "/cashback-bonus/", l: "enCA" },
      { name: "Reload Bonus", url: "/casino-reload-bonus/", l: "enCA" },
      { name: "Bonus Codes", url: "/best-bonus-codes-for-online-casinos/", l: "enCA" },
      { name: "HighRoller Bonus", url: "/highroller-casino-bonus/", l: "enCA" },
      { name: "Non-sticky Bonus", url: "/best-forfeitable-casino-bonuses/", l: "enCA" },
      { name: "Forfeitable Bonus", url: "/best-forfeitable-casino-bonuses/", l: "enCA" },
      { name: "Exclusive Bonus", url: "/exclusive-casino-bonus/", l: "enCA" },
      { name: "Sportsbook Bonuses", url: "/casino-with-sportsbook/", l: "enCA" },
      // Uk

      { name: "Bonus", url: "/casino-bonus/", l: "enGB" },
      { name: "freespins", url: "/free-spins/", l: "enGB" },
      { name: "No deposit bonus", url: "/no-deposit-bonus/", l: "enGB" },
      { name: "Deposit bonuses", url: "/casino-deposit-bonus/", l: "enGB" },
      { name: "Cashback Bonus", url: "/cashback-bonus/", l: "enGB" },
      { name: "Reload Bonus", url: "/casino-reload-bonus/", l: "enGB" },
      { name: "Bonus Codes", url: "/best-bonus-codes-for-online-casinos/", l: "enGB" },
      { name: "HighRoller Bonus", url: "/highroller-casino-bonus/", l: "enGB" },
      { name: "Non-sticky Bonus", url: "/best-forfeitable-casino-bonuses/", l: "enGB" },
      { name: "Forfeitable Bonus", url: "/best-forfeitable-casino-bonuses/", l: "enGB" },
      { name: "Exclusive Bonus", url: "/exclusive-casino-bonus/", l: "enGB" },
      { name: "Sportsbook Bonuses", url: "/casino-with-sportsbook/", l: "enGB" },
    ],
    casinos: [
      { name: "kasinot", url: "/nettikasinot/", l: "fi" },
      { name: "Ilman rekisteröitymistä", url: "//nettikasinot-ilman-rekisteroitymista/", l: "fi" },
      { name: "Uudet kasinot", url: "/uudet-nettikasinot/", l: "fi" },
      { name: "EU/ETA -kasinot", url: "/verovapaat-nettikasinot/", l: "fi" },
      { name: "Suomalaiset kasinot", url: "/suomalaiset-nettikasinot/", l: "fi" },
      { name: "Nopeat kotiutukset", url: "/nopeat-kotiutukset/", l: "fi" },
      { name: "Nopeat kasinot", url: "/nopeat-kotiutukset/", l: "fi" },
      { name: "Verovapaat", url: "/verovapaat-nettikasinot/", l: "fi" },
      { name: "Turvalliset nettikasinot", url: "/turvallinen-nettikasino/", l: "fi" },
      { name: "Kasinot ilman tiliä", url: "/nettikasinot-ilman-rekisteroitymista/", l: "fi" },
      { name: "Mobiilikasinot", url: "/mobiilikasinot/", l: "fi" },
      { name: "Pieni minimitalletus", url: "/minimitalletus/", l: "fi" },
      { name: "Suomenkieliset kasinot", url: "/suomalaiset-nettikasinot/", l: "fi" },
      { name: "Kasinoarvostelut", url: "/kasinoarvostelut/", l: "fi" },
      // En
      { name: "Casinos", url: "/online-casinos/", l: "en" },
      { name: "New Casinos", url: "/new-casinos/", l: "en" },
      { name: "Most Trusted Casinos", url: "/trusted-online-casino/", l: "en" },
      { name: "Safe Casinos", url: "/safe-online-casino/", l: "en" },
      { name: "Fast withdrawal casinos", url: "/fast-withdrawal-casino/", l: "en" },
      { name: "Casino Reviews", url: "/casino-reviews/", l: "en" },
      { name: "Mobile Casinos", url: "/mobile-casino/", l: "en" },
      // Ca
      { name: "Casinos", url: "/online-casinos/", l: "enCA" },
      { name: "New Casinos", url: "/new-casinos/", l: "enCA" },
      { name: "Most Trusted Casinos", url: "/trusted-online-casino/", l: "enCA" },
      { name: "Safe Casinos", url: "/safe-online-casino/", l: "enCA" },
      { name: "Fast withdrawal casinos", url: "/fast-withdrawal-casino/", l: "enCA" },
      { name: "Casino Reviews", url: "/casino-reviews/", l: "enCA" },
      { name: "Mobile Casinos", url: "/mobile-casino/", l: "enCA" },
      // Uk
      { name: "Casinos", url: "/online-casinos/", l: "enGB" },
      { name: "New Casinos", url: "/new-casinos/", l: "enGB" },
      { name: "Most Trusted Casinos", url: "/trusted-online-casino/", l: "enGB" },
      { name: "Safe Casinos", url: "/safe-online-casino/", l: "enGB" },
      { name: "Fast withdrawal casinos", url: "/fast-withdrawal-casino/", l: "enGB" },
      { name: "Casino Reviews", url: "/casino-reviews/", l: "enGB" },
      { name: "Mobile Casinos", url: "/mobile-casino/", l: "enGB" },
    ],
  }

  const toggleLinks = () => {
    setLinksOpen(!linksOpen);
  };
  const PrintLinks = ({items}) => {
    const getLocale = (locale) => {
      if (locale === "fi") return "fi"
      if (locale === "en") return "en"
      if (locale === "enCA") return "ca"
      if (locale === "enGB") return "uk"
    }

    items = items
      .filter(x => locale === x.l)
      .filter(x => location && `/${getLocale(locale) + x.url}` !== location.pathname);
    return (
      <Slider disableNav={false}>
        {
            items && items.length > 0 && items.map(x =>
              <Link
                key={x.url}
                className={`${styles.item}`}
                to={getLocale(locale) + x.url}
              >
                {x.name}
              </Link>
            )
        }
      </Slider>
    )
  }

  return (
    <nav className={`${styles.subNav}`}>
      <span className={`${styles.mainLinks}`}>
        <PrintLinks items={navLinks[type]} />
      </span>
    </nav>
  )
}
export default SubNav
