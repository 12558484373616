import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useContext, useState, useRef } from "react"

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import YouTubeIcon from '@material-ui/icons/YouTube';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import logo from "../../images/tv_set@3x.png"
import logoNoText from "../../images/mrg-notext.png"
import styles from "./header.module.scss"
import r from "../../services/Router"
import t from "../../services/GetTranslationValue"
import ThemeContext from "../../context/ThemeContext"
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { navigate } from "gatsby";
import { getUser, isLoggedIn, setUser, logout } from "../../services/auth";
import { getLocaleAlias } from "../../graphql-assistant/createLocaleTextGetter"
import Drawer from '@material-ui/core/Drawer';
import Login from "../login/login"
import PersonIcon from '@material-ui/icons/Person';
import Popover from '@material-ui/core/Popover';
import Collapse from '@material-ui/core/Collapse';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

const Header = ({ siteTitle, pageContext, props }) => {

  const [menuVisible, setMenuVisible] = useState(false)

  let [loginOpen, setLoginOpen] = useState(false)
  let [loginIndex, setLoginIndex] = useState(0)
  let [accountMenu, setAccountMenu] = useState(false)
  let [accountMenuMobile, setAccountMenuMobile] = useState(false)
  let [uamAnchorEl, setUamAnchorEl] = useState(null);

  const anchorRef = useRef(null);
  // const [searchVisible, setSearchVisible] = useState(false)

  let { profile, setProfile, locale, translations } = useContext(ThemeContext)

  let { alternateLocaleSlugs } = pageContext ? pageContext : {}

  let localeSlugs = {};
  if (alternateLocaleSlugs) {
    ['fi', 'en', 'uk', 'ca'].map(localeAlias => {
      const [ slug ] = alternateLocaleSlugs.filter(slug => slug && slug.includes(`${localeAlias}/`))
      localeSlugs[localeAlias] = slug ? `/${slug}` : `/${localeAlias}/`
    })
  }

  //
  // const searchIndices = [
  //   { name: `FI_BlogPosts`, title: `Pages`, hitComp: `PageHit` },
  //   { name: `FI_GenericPages`, title: `Generic Pages`, hitComp: `GenericPage` },
  //   { name: `FI_GameProviders`, title: `Game Providers`, hitComp: `GameProviders` },
  //   { name: `FI_Casinos`, title: `Casinos`, hitComp: `Casinos` },
  //   { name: `FI_CasinoBonuses`, title: `Bonuses`, hitComp: `Bonuses` },
  // ]
  //
  const handleLogout = async () => {
    logout(() => {
      setProfile({})
      navigate(`/${getLocaleAlias(locale)}`)
    })
  }

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const toggleSubMenu = (e) => {
    e.target.parentElement.classList.toggle(`${styles.isActive}`);
  };

  const closeMenu = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setMenuVisible(false);
  };

  const lpClose = () => {
    setLoginOpen(false);
  }

  const toggleLoginPopup = (index) => {
    setLoginOpen(true);
    setLoginIndex(index);
  }

  const openAccountMenu = (event) => {
    setUamAnchorEl(event.currentTarget);
    setAccountMenu(true);
  };
  const closeAccountMenu = () => {
    setUamAnchorEl(null);
    setAccountMenu(false);
  };

  //
  // const toggleSearch = () => {
  //   setSearchVisible(!searchVisible);
  // };

  let { allSanityNavigationMenu } = useStaticQuery(
    graphql`  {
      allSanityNavigationMenu {
          nodes{
              name
              _id
              displayName
              url
              _rawChildren
          }
      }   
    }`);


  const getNav = (navigation) => {
    // root node
    const root = navigation.find(x => x.name.toLowerCase() === getLocaleAlias(locale).toLowerCase());

    // 1st level (base)
    root.children = root._rawChildren.map(x => {
      return navigation.find(y => y._id === x._ref)
    });

    // 2nd level (children)
    root.children.forEach(child => {
      if (child){
        child.children = child._rawChildren.map(x => {
          return navigation.find(y => y._id === x._ref)
        });

        if (child.children){
          // 3rd level (grand children)
          child.children.forEach(grandchild => {
            grandchild.children = grandchild._rawChildren.map(x => {
              return navigation.find(y => y._id === x._ref)
            });
          })
        }
      }
    })

    return root;
  }
  // function HideOnScroll(props) {
  //   const { children, window } = props;
  //   const trigger = useScrollTrigger({ target: window ? window() : undefined });
  //   return (
  //     <Slide appear={false} direction="down" in={!trigger}>
  //       {children}
  //     </Slide>
  //   );
  // }
  const enableLogin = true;
  const menu = getNav(allSanityNavigationMenu.nodes);
  return (
    // [<HideOnScroll {...props}>
    <ClickAwayListener onClickAway={closeMenu}>
    <AppBar color="secondary">
      <Login isOpen={loginOpen} tabIndex={loginIndex} lpClose={lpClose} />
      <Toolbar disableGutters>
        <div className={`${styles.header}`} style={menuVisible ? { overflow: '', position: ''}: {overflow: 'hidden', position: 'relative'}}>
          <div onClick={toggleMenu} ref={anchorRef}>
            {menuVisible ?
              <CloseIcon className={`${styles.mobileNav}`} /> :
              <MenuIcon className={`${styles.mobileNav}`} />
            }
          </div>
          <Link
            to={r.get(locale, translations, "home", "")}
            className={`${styles.logo}`}
            aria-label={`Mr. Gamble logo`}
          >
            <img className={`gamble-hidden-md-down`} alt={`Mr. Gamble logo`} src={logo} />
            <img className={`gamble-hidden-md-up`} alt={`Mr. Gamble logo`} src={logoNoText} />
          </Link>
          <div className={`${styles.headerSearchbox}`}></div>
          {enableLogin && (!isLoggedIn()  ? (
            <div>
              <Button className={`${styles.menuLogin}`} onClick={() => toggleLoginPopup(0)}>{t.get(translations, "login-login")} / {t.get(translations, "login-signup")}</Button>
            </div>
          ) : [
            <Button className={`${styles.useAccountMenuButton}`} onClick={openAccountMenu}>
              <PersonIcon />
              <div><span>{ profile && profile.user ? profile.user.username : '' }</span></div>
            </Button>,
            <Popover
              id={accountMenu ? "user account menu" : ""}
              open={accountMenu}
              anchorEl={uamAnchorEl}
              onClose={closeAccountMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <div className={styles.userAccountMenu}>
                <Link
                  to={r.get(locale, translations, "myAccount", "")}
                  aria-label={t.get(translations, "login-my-account")}
                >
                  <AccountBoxIcon />
                  <span>{t.get(translations, "login-my-account")}</span>
                </Link>
                <span onClick={async () => await handleLogout()}><ExitToAppIcon />{t.get(translations, "login-logout")}</span>
              </div>
            </Popover>
          ])}

          <div className={`gamble-hidden-md-down ${styles.menuLanguage}`}>
            <span>{t.get(translations, 'language')} :</span>
            <a href={localeSlugs['uk']} aria-label={`English ${t.get(translations, "language")}`}>
              <img src={`/icons/united-kingdom.png`} alt={t.get(translations, 'uk-flag-alt')} />
            </a>
            <a href={localeSlugs['fi']} aria-label={`Finnish ${t.get(translations, "language")}`}>
              <img src={`/icons/finland.png`} alt={t.get(translations, 'fin-flag-alt')} />
            </a>
            <a href={localeSlugs['ca']} aria-label={`Canadian ${t.get(translations, "language")}`}>
              <img src={`/icons/canada.svg`} alt={t.get(translations, 'ca-flag-alt')} />
            </a>
            <a href={localeSlugs['en']} aria-label={`Global ${t.get(translations, "language")}`}>
              <img src={`/icons/global.svg`} alt={t.get(translations, 'global-flag-alt')} />
            </a>
          </div>
          <section className={`${menuVisible ? styles.isVisible : ""} ${styles.menuDrawer}`}>
            <nav className={`${styles.nav} ${ menuVisible ? styles.mobileNavOpen : ''}`}>
              <div className={`gamble-hidden-md-up ${styles.langContainer}`}>
                <div className={`${styles.menuLanguage}`}>
                  <span>{t.get(translations, 'language')} :</span>
                  <a href={'/uk/'} aria-label={`English ${t.get(translations, "language")}`}>
                    <img src={`/icons/united-kingdom.png`} alt={t.get(translations, 'uk-flag-alt')} />
                  </a>
                  <a href={'/fi/'} aria-label={`Finnish ${t.get(translations, "language")}`}>
                    <img src={`/icons/finland.png`} alt={t.get(translations, 'fin-flag-alt')} />
                  </a>
                  <a href={'/ca/'} aria-label={`Canadian ${t.get(translations, "language")}`}>
                    <img src={`/icons/canada.svg`} alt={t.get(translations, 'ca-flag-alt')} />
                  </a>
                  <a href={'/en/'} aria-label={`Global ${t.get(translations, "language")}`}>
                    <img src={`/icons/global.svg`} alt={t.get(translations, 'global-flag-alt')} />
                  </a>
                </div>
              </div>
              <div className={`${styles.menuWrapper}`}>
                {
                  menu && menu.children.map(x => {
                    if (x.children && x.children.length > 0){
                      return <div className={`${styles.subWrapper}`}>
                        <Link to={r.get(locale, translations, "generic", x.url)} className={`${styles.lvlOne} ${styles.subHeader}`}><RemoveRoundedIcon/>{x.displayName}</Link>
                        <div className={`${styles.closeSub}`} onClick={e => toggleSubMenu(e)}>
                          <ArrowBackIosRoundedIcon/>
                        </div>
                        <div className={`${styles.openSub}`} onClick={e => toggleSubMenu(e)}>
                          <ChevronRightRoundedIcon/>
                        </div>
                        <div className={`${styles.itemsWrapper}`}>
                          {x.children.map(y =>{
                            if (y.children && y.children.length > 0){
                              return <div className={`${styles.subWrapper} ${styles.secLevel}`}>
                                <span className={`${styles.subHeader}`}>
                                  <ArrowRightRoundedIcon className={`${styles.menuItemLeftArrow}`}/>
                                  <RemoveRoundedIcon />
                                  {y.displayName}
                                </span>
                                <div className={`${styles.closeSub}`} onClick={e => toggleSubMenu(e)}>
                                  <ArrowBackIosRoundedIcon />
                                </div>
                                <div className={`${styles.openSub}`} onClick={e => toggleSubMenu(e)}>
                                  <ChevronRightRoundedIcon />
                                </div>
                                <div className={`${styles.itemsWrapper}`}>
                                  {y.children.map(z => <Link to={r.get(locale, translations, "generic", z.url)} className={`${styles.menuItem}`}><ArrowRightRoundedIcon/>{z.displayName}</Link>)}
                                </div>
                              </div>
                            }
                            else {
                              return <Link to={r.get(locale, translations, "generic", y.url)} className={`${styles.menuItem}`}><ArrowRightRoundedIcon/>{y.displayName}</Link>
                            }
                          })
                          }
                        </div>
                      </div>
                    }
                    else {
                      return <Link to={r.get(locale, translations, "generic", x.url)} className={`${styles.lvlOne}`}>{x.displayName}</Link>
                    }
                  }
                  )
                }
              </div>
              <div className={`${styles.menuSocialMedias}`}>
                {/* <span>{t.get(translations, 'social-media')} :</span> */}
                <a href="https://www.youtube.com/mrgamble/live" rel="nofollow" aria-label={`Mr. Gamble YouTube`}>
                  <YouTubeIcon />
                </a>
                <a href="https://www.facebook.com/groups/slottikingi/" rel="nofollow" aria-label={`Mr. Gamble Facebook`}>
                  <FacebookIcon />
                </a>
                <a href="https://twitter.com/mrgamble_" rel="nofollow" aria-label={`Mr. Gamble Twitter`}>
                  <TwitterIcon />
                </a>
                <a href="https://discord.gg/tsQvQf6" rel="nofollow" aria-label={`Mr. Gamble Discord`}>
                  <p className={`${styles.discordLogo}`}></p>
                </a>
                <a href="https://www.twitch.tv/mrgambleslots" rel="nofollow" aria-label={`Mr. Gamble Twitch`}>
                  <span className={`${styles.twitchLogo}`}></span>
                </a>
              </div>
            </nav>
          </section>
        </div>
      </Toolbar>
    </AppBar>
  </ClickAwayListener>
    // </HideOnScroll>]
  )
}
export default Header
