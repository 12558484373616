import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useContext } from "react"
import styles from "./footer.module.scss"
import Grid from "@material-ui/core/Grid"
import r from "../../services/Router"
import t from "../../services/GetTranslationValue"
import ThemeContext from "../../context/ThemeContext"
import YouTubeIcon from '@material-ui/icons/YouTube';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import { getLocaleAlias, localizeItem } from "../../graphql-assistant/createLocaleTextGetter"

const Footer = ({location}) => {
  let { translations, locale } = useContext(ThemeContext)

  let { sanityFooter } = useStaticQuery(
    graphql`
      query {
        sanityFooter {
          facebook
          twitch
          twitter
          youtube
          googlePlus
          firstLinks {
              ...LocaleKeyValue
          }
          secondLinks {
            ...LocaleKeyValue
          }
          thirdLinks {
              ...LocaleKeyValue
          }
        }
      }
    `
  );

  const dataLocale = (locale.startsWith('en')) ? "en" : "fi";
  sanityFooter = localizeItem(sanityFooter, [dataLocale]);

  const alias = getLocaleAlias(locale);
  return (
    <Grid container spacing={0} className={`${styles.footer}`}>
      <Grid container spacing={0} className={`${styles.main}`}>
        <Grid item xs={12} md={6} className={`${styles.info}`}>
          <div className={`${styles.footerContacts}`}>
            <p className={`${styles.mainTitle}`}>{t.get(translations, "footer-header")}</p>
            <span>{t.get(translations, 'email')} :</span><p>{t.get(translations, "footer-summary")}</p>
            <div className={`${styles.footerSocialMedias}`}>
              <span>{t.get(translations, 'social-media')} :</span>
              <a className={`${styles.logo}`} rel="nofollow" href="https://www.youtube.com/mrgamble/live" aria-label={`Mr. Gamble YouTube`}>
                <YouTubeIcon />
              </a>
              <a className={`${styles.logo}`} rel="nofollow" href="https://www.facebook.com/groups/slottikingi/" aria-label={`Mr. Gamble Facebook`}>
                <FacebookIcon />
              </a>
              <a className={`${styles.logo}`} rel="nofollow" href="https://twitter.com/mrgamble_" aria-label={`Mr. Gamble Twitter`}>
                <TwitterIcon />
              </a>
              <a className={`${styles.logo}`} rel="nofollow" href="https://discord.gg/tsQvQf6" aria-label={`Mr. Gamble Discord`}>
                <p className={`${styles.discordLogo}`}></p>
              </a>
              <a className={`${styles.logo}`} rel="nofollow" href="https://www.twitch.tv/mrgambleslots" aria-label={`Mr. Gamble Twitch`}>
                <span className={`${styles.twitchLogo}`}></span>
              </a>
            </div>
          </div>
          <div className={`${styles.footerLanguage}`}>
            <span>{t.get(translations, 'language-select')} :</span>
            <a href={'/uk/'} aria-label={`English ${t.get(translations, "language")}`}>
              <img src={`/icons/united-kingdom.png`} alt={t.get(translations, 'uk-flag-alt')} />
            </a>
            <a href={'/fi/'} aria-label={`Finnish ${t.get(translations, "language")}`}>
              <img src={`/icons/finland.png`} alt={t.get(translations, 'fin-flag-alt')} />
            </a>
            <a href={'/ca/'} aria-label={`Canadian ${t.get(translations, "language")}`}>
              <img src={`/icons/canada.svg`} alt={t.get(translations, 'ca-flag-alt')} />
            </a>
            <a href={'/en/'} aria-label={`Global ${t.get(translations, "language")}`}>
              <img src={`/icons/global.svg`} alt={t.get(translations, 'global-flag-alt')} />
            </a>
          </div>
          <div className={`${styles.footerlogos}`}>
            <a className={`${styles.footerDMCA}`} rel="nofollow" aria-label="hidden" href="//www.dmca.com/Protection/Status.aspx?ID=a4f72312-faa0-4cc1-bb4d-8bbd175d92f9"
              title="DMCA.com Protection Status"> <img
                src="https://images.dmca.com/Badges/_dmca_premi_badge_4.png?ID=a4f72312-faa0-4cc1-bb4d-8bbd175d92f9"
                alt="DMCA.com Protection Status" /></a>
            <img className={`${styles.warningLogo}`} alt={t.get(translations, 'footer-age-restriction')} src={`/icons/Artboard-32.svg`} />
            {locale === "enGB" &&
              <a className={`${styles.footerGambleAware}`} rel="nofollow" aria-label="hidden" href="https://gambleaware.org/"
                title="BeGambleAware"> 
                  <img
                  src={`/icons/begambleawareorg_white_png.png`}
                    alt="Gamblea Aware" 
                  />
              </a>
            }
            <a className={`${styles.footerGambleAware}`} rel="nofollow" aria-label="hidden" href="https://www.sigma.com.mt/"
              title="Sigma Trusted Summit">
              <img
                src={`/icons/sigma.png`}
                alt="Sigma Trusted Summit"
              />
            </a>
          </div>
        </Grid>
        <Grid item xs={12} md={2} className={`${styles.links}`}>
          <p>{t.get(translations, "footer-first-header")}</p>
          <div>
            {sanityFooter.firstLinks.map(x => (
              <Link key={x.name} to={alias + "/" + x.value + "/"}>{x.name}</Link>
            ))}
          </div>
        </Grid>
        <Grid item xs={12} md={2} className={`${styles.links}`}>
          <p>{t.get(translations, "footer-second-header")}</p>
          <div>
            {sanityFooter.secondLinks.map(x => (
              <Link key={x.name} to={alias + "/" + x.value + "/"}>{x.name}</Link>
            ))}
          </div>
        </Grid>
        <Grid item xs={12} md={2} className={`${styles.links}`}>
          <p>{t.get(translations, "footer-third-header")}</p>
          <div>
            {sanityFooter.thirdLinks.map(x => (
              <Link key={x.name} to={alias + "/" + x.value + "/"}>{x.name}</Link>
            ))}
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default Footer
